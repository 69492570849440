<template>
  <v-menu
    open-on-hover
    v-model="show"
    :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <!-- ACTIVADOR -->
      <v-btn
        icon
        small
        outlined
        v-on="on">
      <v-icon>mdi-chevron-down</v-icon></v-btn>
    </template>
    <v-list class="d-flex flex-column px-2">
      <slot name="content" />
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data () {
    return {
      show: false
    }
  }
}
</script>
